<template>
  <v-container class="nopadding">
    <v-toolbar fixed
      class="toolbar-noshadow toolbar-page-name">
        <router-link
          v-if="$route.name !== 'stafflist'"
          class="pr-4"
          :to="{ name: 'stafflist' }">
          <v-icon>mdi-arrow-left</v-icon>
        </router-link>
      <v-toolbar-title><strong>{{ pageName }}</strong></v-toolbar-title>
    </v-toolbar>
    <v-container class="page-container">
      <router-view></router-view>

      <v-btn
        v-if="$route.name == 'stafflist'"
        class="add-btn"
        color="primary"
        dark absolute bottom right
        fab
        :to="{ name: 'addstaff'}"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-container>
  </v-container>
</template>
<script>

  export default {
    name: 'Staff',
    computed: {
      pageName() {
        let name = 'Staff'
        switch (this.$route.name) {
          case 'viewstaff':
            name = 'Staff Detail'
            break;
          case 'editstaff':
            name = 'Edit Staff'
            break;
          case 'addstaff':
            name = 'Add Staff'
            break;
          default:
            name = 'Staff'
            break;
        }
        return name;
      }
    }
  }
</script>
<style lang="scss" scoped>
  .nopadding {
    padding: 0;
    @media screen and (max-width: 768px){
      padding-bottom: 80px;
    }
  }
  .page-container {
    padding-top: 16px;
    padding-bottom: 100px;
    @media screen and (max-width: 768px){
      padding-bottom: 10px;
    }
  }
  .add-btn {
    bottom: 16px!important;
  }
  ::v-deep {
    a{
      text-decoration: none;
    }
    @media screen and (max-width: 768px){
      .toolbar-page-name {
        box-shadow: none!important;
        border-bottom: 1px solid lightgray;
      }
    }
  }
</style>
